@import 'figma.scss';
@import 'dashboard.scss';

html,
body {
  overflow-x: hidden;
}

@media (max-width: 991px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px;
    bottom: 0;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    background-color: var(--gray-dark);
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  .offcanvas-collapse.open {
    -webkit-transform: translateX(-1rem);
    transform: translateX(-1rem);  
  }
}
