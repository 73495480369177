.header-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #000000;
}

.nav-user-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
  color: #348E93 !important;
}

.nav-menu-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 350;
  font-size: 15px;
  line-height: 24px;
  color: #5C5C5C !important;
}

.nav-ava {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.dashboard-card {
  border-radius: 10px;
  background-image: linear-gradient(to left, rgba(255, 0, 0, 0), rgb(255, 171, 171));
}

html,
body {
  overflow-x: hidden;
}

@media (max-width: 991px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px;
    bottom: 0;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    background-color: var(--gray-dark);
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  .offcanvas-collapse.open {
    -webkit-transform: translateX(-1rem);
    transform: translateX(-1rem);
  }
}