.header-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #000000;
}

.nav-user-name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 24px;
  color: #348E93 !important;        
}

.nav-menu-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 350;
  font-size: 15px;
  line-height: 24px;
  color: #5C5C5C !important;
}

.nav-ava {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}